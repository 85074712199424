import recipientsService from "../services/recipients.service";
import { parseFormError } from "../services/utils";

const initialState = {
  recipients: [],
  importedRecipients: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    recipient: null,
    error: null,
  }
};

export const recipients = {
  namespaced: true,
  state: initialState,
  actions: {
    async getRecipients({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await recipientsService.getRecipients(state.page);
      commit("SET_RECIPIENTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async getAllRecipients({ commit }) {
      commit("SET_LOADING", true);
      const response = await recipientsService.getAllRecipients();
      commit("SET_RECIPIENTS", response);
      commit("SET_LOADING", false);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getRecipients");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, recipient: null };
      commit("SET_FORM", value);
    },
    async addRecipient({ dispatch, commit }, recipient) {
      try {
        await recipientsService.addRecipient(recipient);
        dispatch("stopEditing");
        dispatch("getRecipients");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, recipient) {
      const value = { open: true, recipient: recipient };
      commit("SET_FORM", value);
    },
    async updateRecipient({ dispatch, commit }, recipient) {
      try {
        await recipientsService.updateRecipient(recipient);
        dispatch("stopEditing");
        dispatch("getRecipients");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, recipient: null };
      commit("SET_FORM", value);
    },
    async importRecipients({ commit }, fileData) {
      commit("SET_LOADING", true);
      try {
        const response = await recipientsService.importRecipients(fileData);
        commit("SET_IMPORTED_RECIPIENTS", response.data); 
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        commit('SET_FORM_ERROR', parseFormError(error)); 
      }
    },
  },
  mutations: {
    SET_RECIPIENTS(state, recipients) {
      state.recipients = recipients;
      state.loading = false;
    },
    SET_IMPORTED_RECIPIENTS(STATE, importedRecipients){
      STATE.importedRecipients = importedRecipients;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page;
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FORM(state, { open, recipient }) {
      state.form.open = open;
      state.form.recipient = recipient;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error;
    },
    resetState(state) {
      state.recipients = [];
      state.importedRecipients = [];
      state.loading = false;
      state.form = {
        open: false,
        recipient: null,
        error: null,
      };
    }
  },
};
