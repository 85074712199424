<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Envios</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                      +&nbsp; Novo Envio
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="spinner-border" role="status" v-if="loading">
              <span class="sr-only">Carregando...</span>
            </div>
            <div class="px-0 pb-0 card-body" v-else>
              <div class="table-responsive">
                <table id="shipments-list" ref="shipmentsList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th><strong>Enviar</strong></th>
                      <th><strong>Gerar Etiquetas</strong></th>
                      <th><strong>Remetente</strong></th>
                      <th><strong>Destinatário</strong></th>
                      <th><strong>Conteúdo</strong></th>
                      <th><strong>Qtd</strong></th>
                      <th><strong>Previsão de Postagem</strong></th>
                      <th><strong>Status</strong></th>
                      <th><strong>Criado em</strong></th>
                      <th><strong>Editar</strong></th>
                    </tr>

                  </thead>
                  <tbody class="text-sm">
                    <shipment-tile v-for="(shipment, index) in shipments" :shipment="shipment" :key="index" />
                  </tbody>
                </table>
              </div>
            </div>
            <pagination 
              :current="page"
              :perPage="perPage"
              :onChangePage="changeToPage"
              :totalPages="lastPage"
              :totalValues="total"
            /> 
          </div>
        </div>
      </div>
    </div>
  
    <shipment-modal />
    
  </template>
  
  <script>
  import ShipmentTile from "./components/ShipmentTile.vue";
  import ShipmentModal from "./components/ShipmentModal.vue";
  import { mapState } from 'vuex'
  import Pagination from "../components/Pagination.vue";
  import list_mixin from "../mixins/list_mixin";
  
  export default {
    name: "Shipments",
  
    components: {
      ShipmentTile,
      ShipmentModal,
      Pagination
    },
  
    mixins: [list_mixin('shipments')],
  
    computed: {
      ...mapState('shipments', {
        shipments: state => state.shipments,
      }),
    },
  
    async created() {
      try {
        await this.$store.dispatch("shipments/getShipments");
      } catch (error) {
        console.error(error);
      }
    },
  };
  </script>
  
  <style>
  td {
    padding: 12px 24px !important;
  }
  </style>
  