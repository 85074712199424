import api from "./axios";

export default {
    async getShipments(page = 1) {
        const response = await api.get(`/shipments?page=${page}`);
        return response.data;
    },
    async addShipment(shipment) {
        const response = await api.post('/shipments', shipment);
        return response.data;
    },
    async updateShipment(shipment) {
        const response = await api.put(`/shipments/${shipment.id}`, shipment);
        return response.data;
    },
    async deleteShipment(shipment) {
        const response = await api.delete(`/shipments/${shipment.id}`, shipment);
        return response.data;
    },
    // Método para enviar pré-postagens
    async sendPrePostagem(shipment) {
        const response = await api.post('/prepostagem', shipment);
        return response.data;
    },
    async getSenderDetails(senderId) {
        const response = await api.get(`/senders/${senderId}`);
        return response.data;
    },
    async getRecipientDetails(recipientId) {
        const response = await api.get(`/recipients/${recipientId}`);
        return response.data;
    },
    async getShipmentsDetails (shipmentId) {
        const response = await api.get(`/shipments/${shipmentId}`);
        return response.data;
    },
    async getStickers (shipmentId){
        const response = await api.get(`/stickers/${shipmentId}`, {responseType: 'blob'});
        return response.data;
    }
};
